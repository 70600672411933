<template>
	<div class="upload-opus-view">
		<div class="upload-opus-tab">
			<MusicType :width="952" :type="type" :tabsList="tabsList" :dropdownList="dropdownList"></MusicType>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType"
	import {
		setMyInfo,
		getMyInfo,
	} from "@/utils/common/user";
	// import {
	// 	myInfo
	// } from "@/api/Mine";
	import {
		getUserInfo
	} from "@/utils/common/user";

	export default {
		props: {},
		data() {
			return {
				// tabs数据
				tabsList: [],
				tabsList2: [{
						name: this.$t("recommend.finishedMusic"),
						url: '/uploadopus/finishmusic'
					},
					{
						name: this.$t("recommend.demoSea"),
						url: '/uploadopus/abroaddemo'
					},
					{
						name: this.$t("recommend.demoLocal"),
						url: '/uploadopus/outbackdemo'
					},
					{
						name: this.$t("recommend.WorkCollaboration"),
						url: '/uploadopus/collaborationarea'
					}
				],
				tabsList1: [{
					name: this.$t('Musiccertification'),
					url: '/uploadopus/musiciancertification'
				}],
				// 全部下来菜单
				dropdownList: [
					// {
					//   name: '全部'
					// },
					// {
					//   name: '分类1'
					// },
					// {
					//   name: '分类2'
					// }
				],
				type: false
			}
		},
		components: {
			MusicType
		},
		computed: {},
		created() {},
		mounted() {

			let userInfo = JSON.parse(this.getMyInfo())
			this.$store.state.loginname = userInfo.name;
			this.$store.state.countryId = userInfo.countryId
			console.log(userInfo, 'getMyInfo1111111111111111', this.$store.state.loginname)
			if (userInfo.identity == 3) {
				if (userInfo.countryId == 1) {
					let arr = [{
							name: this.$t("recommend.finishedMusic"),
							url: '/uploadopus/finishmusic'
						},
						{
							name: this.$t("recommend.demoLocal"),
							url: '/uploadopus/outbackdemo'
						},
						{
							name: this.$t("recommend.uploadBeat"),
							url: '/uploadopus/uploadbeat'
						}
					]
					this.tabsList = arr
				} else if (userInfo.countryId != 1) {
					console.log(',,,,,,,,,,,,,,,,,,')
					let arr = [{
							name: this.$t("recommend.finishedMusic"),
							url: '/uploadopus/finishmusic'
						},
						{
							name: this.$t("recommend.demoSea"),
							url: '/uploadopus/abroaddemo'
						},
						{
							name: this.$t("recommend.uploadBeat"),
							url: '/uploadopus/uploadbeat'
						}
					]
					this.tabsList = arr
				}
			}else {

				let arr = [{
					name: this.$t('Musiccertification'),
					url: '/uploadopus/musiciancertification'
				}]
				this.tabsList = arr
			}
			// this.$store.state.surplus_sum = res.data.surplus;
			// this.$store.state.is_prove = res.data.is_prove;

			// myInfo().then((res) => {

			// 	// this.userName = res.data.name;
			// 	// this.userImage = res.data.image;
			// 	// this.userIntroduce = res.data.introduce;
			// 	// this.userDay = res.data.day;
			// 	// this.userFollow = res.data.follow_count;
			// 	// this.userFavorite = res.data.favorite_count;
			// 	// this.userShopping = res.data.shopping_cart_count;
			// 	// this.userPurchase = res.data.withdrawal;
			// 	this.$store.state.loginname = res.data.name;
			// 	console.log(this.$store.state.loginname, 'myInfo')
			// 	let userInfo = JSON.parse(this.getUserInfo())
			// 	if (userInfo.identity == 3) {
			// 		let arr = [{
			// 				name: '成品音乐',
			// 				url: '/uploadopus/finishmusic'
			// 			},
			// 			{
			// 				name: 'DEMO(海外)',
			// 				url: '/uploadopus/abroaddemo'
			// 			},
			// 			{
			// 				name: 'DEMO(内地)',
			// 				url: '/uploadopus/outbackdemo'
			// 			},
			// 			// {
			// 			//   name: '作品协作区',
			// 			//   url: '/uploadopus/collaborationarea'
			// 			// }
			// 		]
			// 		this.tabsList = arr
			// 	} else {
			// 		let arr = [{
			// 			name: '音乐人认证',
			// 			url: '/uploadopus/musiciancertification'
			// 		}]
			// 		this.tabsList = arr
			// 	}
			// 	// this.$store.state.surplus_sum = res.data.surplus;
			// 	// this.$store.state.is_prove = res.data.is_prove;
			// });
		},
		watch: {

		},
		methods: {
			getUserInfo,
			getMyInfo,

		}
	}
</script>

<style scoped lang='less'>
</style>
